<template>
  <div id="app">
    <resour />
    <div class="aoo_content">
      <div class="app_left">
        <el-container>
          <el-header id="header" style="height:1.102941rem;">
            <el-image class="picing" :src="img"></el-image>
              <span v-if="celebrity" class="VVVV"></span>
            <!-- <div
              v-if="celebrity"
              style="color: red;font-size: 16px;position: absolute;margin-top: 125px;margin-left: 20px;"
            >知识网红</div> -->
            <div class="xq">
              <div class="zc">
                <span class="ts_1">
                  <!-- <img class="score" src="@/assets/icon/v.png" /> -->
                  {{ user }}
                </span>
                <span class="zc_title">{{ title }}</span>
              </div>
              <div>
                <span class="ts_2">{{ works }}</span>篇原创作品|
                <span class="ts_2">{{ browse }}</span>人浏览|
                <span class="ts_2">{{ dowmload }}</span>次下载
              </div>
              <div class="zc_introduce">
                <span>{{ introduce }}</span>
              </div>
              <div class="zc_introduce">
                <span>用户等级:{{ level }}</span>
              </div>
            </div>
            <!-- <div class="right">
          <div class="ts_3">
            <img class="score" src="@/assets/icon/d.png" />{{ school }}
          </div>
          <div class="ts_3">
            <img class="score" src="@/assets/icon/h.png" />{{ Belonging }}
          </div>
            </div>-->
            <!-- 如登录用户为作者本人 这不提供订阅功能 -->
            <div v-if="isSelf">
              <el-button
                type="primary"
                size="mini"
                class="subs"
                v-show="!show3"
                @click="dialogVisible = true"
              >+ 订阅</el-button>
              <el-button
                type="info"
                size="mini"
                class="subs"
                v-show="show3"
                @click="subsCancle"
              >取消订阅</el-button>
            </div>
            <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="70%"
              :before-close="handleClose"
            >
              <p>个性化订阅支持两种订阅方式，基本订阅+折扣和一次性付费。被订阅对象为上传资源者，基本订阅+折扣指订阅者支付基本费用后，下载被订阅者所上传的资源将可以在原价的基础上享受相应的折扣；而一次性付费指订阅者支付一定费用后，将可以免费下载被订阅者所上传的所有资源。</p>
              <p>订阅者可以在个人中心-我的订阅页面，查看自己订阅的用户。订阅者可以单方面取消订阅，取消订阅后，将不在享受订阅的所有优惠，所有资源恢复原价；同时，订阅时所支付的费用将不会退还；</p>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="subsChoose">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 订阅方式 -->
            <div id="subsWay" v-show="show1">
              <h3 id="border-b">订阅方式</h3>
              <template>
                <el-radio-group v-model="radio">
                  <el-radio :label="6" @change="isChoose">基本费用+折扣</el-radio>
                  <el-radio :label="7" @change="isChoose">一次性付费</el-radio>
                </el-radio-group>
              </template>
            </div>
            <!-- 订阅费用 -->
            <div id="subsCost" v-show="show_cost">
              <div class="public-div" v-show="payWay_type6">
                <h4 class="center font14 border-b">基本费用+折扣</h4>
                <div>
                  <!-- <p class="tip center"><span>包月</span><span>包季</span><span>包年</span></p> -->
                  <template>
                    <el-radio-group v-model="baseRadio">
                      <!-- <el-radio :label="0" @change="isChooseDataOfType">{{&nbsp;}}</el-radio>
                  <el-radio :label="1" @change="isChooseDataOfType">{{&nbsp;}}</el-radio>
                      <el-radio :label="2" @change="isChooseDataOfType">{{&nbsp;}}</el-radio>-->
                      <el-radio :label="0" @change="isChooseDataOfType">包月</el-radio>
                      <el-radio :label="1" @change="isChooseDataOfType">包季</el-radio>
                      <el-radio :label="2" @change="isChooseDataOfType">包年</el-radio>
                    </el-radio-group>
                  </template>
                  <div class="detailCost center">
                    <span>
                      <font class="tip2">{{ baseMoney }}</font> 知识币+
                      <font class="tip2">{{ baseDiscount }}</font> 折扣
                    </span>
                  </div>
                  <div class="submitBtn padd15 center">
                    <el-button type="info" size="mini" @click="cancel">取消</el-button>
                    <el-button type size="mini" @click="confirmPays">确认</el-button>
                  </div>
                </div>
              </div>
              <!-- 一次性 -->
              <div v-show="payWay_type7">
                <h4 class="center font14 border-b">一次性付费</h4>
                <div>
                  <p class="tip center">
                    <span>包月</span>
                    <span>包季</span>
                    <span>包年</span>
                  </p>
                  <template>
                    <el-radio-group v-model="baseRadio2">
                      <el-radio :label="3" @change="isChooseDataOfType">{{&nbsp;}}</el-radio>
                      <el-radio :label="4" @change="isChooseDataOfType">{{&nbsp;}}</el-radio>
                      <el-radio :label="5" @change="isChooseDataOfType">{{&nbsp;}}</el-radio>
                    </el-radio-group>
                  </template>
                  <div class="detailCost center">
                    <font class="tip2">{{ onceMoney }}</font> 知识币
                  </div>
                  <div class="submitBtn padd15 center">
                    <el-button type="info" size="mini" @click="cancel">取消</el-button>
                    <el-button type size="mini" @click="confirmPays">确认</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-header>
          <el-container>
            <!-- <el-aside width="200px">
              <el-menu default-active="1" class="el-menu-vertical-demo" @select="handleSelect">
                <el-menu-item index="1">
                  <i class="el-icon-menu"></i>
                  <span slot="title">全部文档({{ allfile }})</span>
                </el-menu-item>

                <el-menu-item index="2">
                  <i class="el-icon-setting"></i>
                  <span slot="title">免费文档({{ Freefile }})</span>
                </el-menu-item>
                <el-menu-item index="3">
                  <i class="el-icon-setting"></i>
                  <span slot="title">付费文档({{ payfile }})</span>
                </el-menu-item>
              </el-menu>
              <div class="Hotfile">
                <span class="gg">热门文档</span>
                <ul class="Hotfile_center">
                  <li
                    class="plcont"
                    v-for="(item, index) in hotlistlist.slice(0, 5)"
                    :key="index"
                    @click="change(item)"
                  >
                    <span class="inx">{{ index + 1 }}</span>
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
            </el-aside>-->
            <el-main>
              <el-tabs type="border-card" v-model="states" @tab-click="handleSelect()">
                <el-tab-pane :label="'全部文档('+allfile+')'" name="1"></el-tab-pane>
                <el-tab-pane :label="'免费文档('+Freefile+')'" name="2"></el-tab-pane>
                <el-tab-pane :label="'付费文档('+payfile+')'" name="3"></el-tab-pane>
              </el-tabs>
              <el-table :data="Collectionlist" @row-click="handle">
                <el-table-column prop="name" label="文档名" width="150"></el-table-column>
                <el-table-column prop="Price" label="价格"></el-table-column>
                <el-table-column prop="score" label="评分"></el-table-column>
                <el-table-column prop="read" label="浏览量"></el-table-column>
                <el-table-column prop="download" label="下载量"></el-table-column>
                <el-table-column prop="Time" label="上传时间" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ YearMMdd(scope.row.Time) }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-main>
          </el-container>
        </el-container>
      </div>
      <div class="app_right">
        <div class="right_top">个性化知识聚合</div>
        <personality :personality="'期刊推荐'" :polymerization="periodical"></personality>
        <personality class="mar_top" :personality="'词条推荐'" :polymerization="Entry"></personality>
      </div>
    </div>
    <!-- 支付校验弹窗 -->
    <el-dialog
      title="订阅核对"
      :visible.sync="centerDialogVisible25"
      width="30%"
      :center="true"
      :close-on-click-modal="false"
    >
      <span>订阅信息如下:</span>
      <div>
        <li class="pad15-t-b">
          作者用户名:
          <font class="color-000">{{ subsInfoShow.user }}</font>
        </li>
        <li>
          订阅类型:
          <font class="color-000">{{ subsInfoShow.type }}</font>
        </li>
        <li class="pad15-t-b">
          过期时间:
          <font class="color-000">{{ subsInfoShow.expires }}</font>
        </li>
      </div>
      <!-- 基本 + 折扣 -->
      <span v-show="payWay">
        订阅后下载该作者资源将享受
        <font class="font16">{{ subsInfoShow.discount }}</font> 折优惠。
      </span>
      <!-- 一次性 -->
      <span v-show="!payWay">订阅后将享受免费下载该作者所有资源~</span>
      <div class="pad15-t">
        <span>
          你的知识币
          <font class="tip2">{{ this.newestMoney }}</font> ->
          <font class="tip2">{{ subsInfoShow.coin }}</font> 确认订阅吗?
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible25 = false">取 消</el-button>
        <el-button type="primary" :loading="payloading" @click="finallyPay">确定支付</el-button>
      </span>
    </el-dialog>
    <!-- 取消订阅弹窗 -->
    <el-dialog
      title="取消订阅"
      :visible.sync="centerDialogVisible26"
      width="30%"
      center
      :close-on-click-modal="false"
    >
      <div>
        您已订阅用户:
        <font color="#4c4743">{{ user }}</font> ,如果取消订阅,费用不予退还。
      </div>
      <div class="pad15-t-b">同时如有折扣优惠,将不再享受。</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible26 = false">取 消</el-button>
        <el-button type="primary" :loading="payloading" @click="cancelConfirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {
  INSERT,
  QUERY,
  DELETE,
  QUERYED,
  UPDATE,
  UPDATES,
  INSERTS
} from "@/services/dao.js";
import resour from "@/components/resour_search";
import { getTimeFormat, Bringbill } from "@/js/common";
import personality from "@/views/Resource_transactions/Resource _expert/personality/index.vue";
import {
  integralAddOne,
  DateAdd,
  showTimeFormat,
  showTimeFormat2,
  subtr,
  accAdd
} from "@/js/common";
import { updateread } from "@/js/common/Resoures";
export default {
  components: {
    resour,
    personality
  },
  data() {
    return {
      periodical: [], //期刊
      Entry: [], //词条
      states: "1",
      centerDialogVisible25: false,
      centerDialogVisible26: false, // 取消订阅
      img: require("@/assets/timg (9).png"),
      user: "",
      title: "",
      works: "0",
      browse: "",
      dowmload: 0,
      dialogVisible: false,
      introduce: "",
      school: "",
      Belonging: "",
      score: "",
      value: 3.7,
      allfile: "0",
      Freefile: "0",
      level: 0,
      celebrity: false,
      payfile: "0",
      Collectionlist: [
        {
          id: "0",
          img: require("@/assets/img/WORD.png"),
          name: "利用python进行数据分析",
          Price: "2知识币",
          score: "4.5",
          read: "360",
          download: "590",
          time: "2020-6-3"
        },
        {
          id: "1",
          img: require("@/assets/img/WORD.png"),
          name: "利用python进行数据分析",
          Price: "免费",
          score: "4.5",
          read: "360",
          download: "590",
          time: "2020-6-3"
        }
      ],
      initiallist: [],
      hotlistlist: [],
      radio: 0, // 默认初始化的数据是 基本+折扣，但点击一次只能展示一个块
      baseRadio: 0, // 基本+折扣 默认包月
      baseRadio2: 3, // 一次性 默认包月
      payWay: true, // 付款方式--基本+折扣--默认显示
      subsInfo: {
        t_60: "",
        d_60: "",
        t_61: "",
        d_61: "",
        t_62: "",
        d_62: "",
        t_73: "",
        t_74: "",
        t_75: ""
      }, // 用户设置的订阅信息
      isSelf: false, // 登录用户是否为作者本人
      show1: false, // 基本或一次性导航显隐控制
      show3: false, // 订阅/取消订阅按钮 显隐
      show_cost: false, // 订阅费用展示
      payWay_type6: false, // 基本加折扣展示框
      payWay_type7: false, // 一次性付费展示框
      baseMoney: "", // 基本费用--费用
      baseDiscount: "", // 基本折扣-- 折扣
      onceMoney: "", // 一次性付费 -- 费用
      subsInfoShow: {
        // 订阅核对
        user: "",
        type: "",
        expires2: "",
        expires: "",
        discount: "",
        coin: "",
        input: 0 // 被订阅者收入
      },
      payloading: false,
      newestMoney: "",
      modify_id: -1 // SubscribeUserInfo 返回的有效订阅 数据行 id
    };
  },
  created() {
    this.init();
    this.hotinit();
    this.userinfo();
    this.knowledge(); ///个性知识聚合
    this.Collectionlist = [];
    if (
      !(
        this.$store.state.userinfo.id === undefined ||
        this.$store.state.userinfo.id === null ||
        this.$store.state.userinfo.id == ""
      ) &&
      this.$store.state.userinfo.id != this.$route.query.id
    ) {
      this.initIsSubs();
    } else {
      this.isSelf = false; // 当前登录用户为作者本人
    }
    // console.log(this.$store.state.userinfo)
  },
  methods: {
    unifiedSearch(firstLable, type) {
      const formData = new FormData(); //为XHR传参提供便利
      formData.append("keywords", firstLable);
      formData.append("page", 1);
      formData.append("count", type == 0 ? 10 : 5);
      formData.append("type", type);
      console.log(formData);
      console.log(formData.get("keywords"));
      axios
        .post(this.api.LoginURL.concat("/common/unifiedSearch"), formData)
        .then(res => {
          console.log("res: ", res);
          if (type == 0) {
            for (let i = 0; i < res.data.data.rows.length; i++) {
              this.periodical.push({
                title: res.data.data.rows[i].title,
                name: res.data.data.rows[i].journal
              });
            }
          }
          if (type == 1) {
            for (let i = 0; i < res.data.data.rows.length; i++) {
              this.Entry.push({
                title: res.data.data.rows[i].Title,
                name: res.data.data.rows[i].DescribeText
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    ///个性知识聚合
    async knowledge() {
      //  this.$store.state.userinfo.id
      //通过用户ID查找标签
      let Users = await QUERYED(
        "POST",
        "",
        'User(where: {id: {_eq: "' +
          this.$route.query.id +
          '"}}) { firstLable }'
      );
      console.log("Users.data.User[0]",Users.data.User[0]);
      ///聚合
      this.unifiedSearch(Users.data.User[0].firstLable, 0);
      ///词条
      this.unifiedSearch(Users.data.User[0].firstLable, 1);
    },
    async init() {
      let res = await QUERY(
        "POST",
        "",
        'ZYDocument(where: {user: {_eq: "' +
          this.$route.query.id +
          '"}}) { id img   name  read download   score  score  Price Time }'
      );
      this.dowmload = 0;
      this.browse = 0;
      for (let i = 0; i < res.data.ZYDocument.length; i++) {
        res.data.ZYDocument[i].img = this.api.LoginURL.concat(
          res.data.ZYDocument[i].img
        );

        // res.data.ZYDocument[i].Time = getTimeFormat(
        //   Date.parse(res.data.ZYDocument[i].Time)
        // );
        this.dowmload = this.dowmload * 1 + res.data.ZYDocument[i].download * 1;
        this.browse = this.browse * 1 + res.data.ZYDocument[i].read * 1;
      }
      this.works = res.data.ZYDocument.length;
      this.Collectionlist = res.data.ZYDocument;
      this.Collectionlist.sort(function(a, b) {
        return b.read - a.read;
      });
      this.initiallist = this.Collectionlist;
      const dest_1 = [];
      const dest_2 = [];
      for (var i = 0; i < this.initiallist.length; i++) {
        var ai = this.initiallist[i].Price;
        this.allfile = this.initiallist.length;
        if (ai == "0") {
          dest_1.push(this.initiallist[i]);
          this.Freefile = dest_1.length;
        } else {
          dest_2.push(this.initiallist[i]);
          this.payfile = dest_2.length;
        }
      }
    },
    async userinfo() {
      /**
       * ZYUser 用于线上服务器，反应太慢，改为查User表
       */
      // let info = await QUERY(
      //   "post",
      //   "",
      //   "  ZYUser(where: {id: {_eq: " +
      //     this.$route.query.id +
      //     "}}) {    id              introduce     circleurl    title        username    value    works    }"
      // );
      let info = await QUERYED(
        "post",
        "",
        "User(where: {id: {_eq: " +
          this.$route.query.id +
          "}}) {    id     celebrity     level     intro    img    title        name    value    articleNum    }"
      );

      console.log("344: ", info);
      if (info.data.User[0] !== undefined) {
        let userinfo = info.data.User[0];
        this.img = this.api.LoginURL.concat(userinfo.img);
        this.user = userinfo.name;
        this.title = userinfo.title;
        this.celebrity = userinfo.celebrity;
        this.introduce = userinfo.intro;
        this.level = userinfo.level;
        this.value = userinfo.value;
      }
    },
    async hotinit() {
      let res = await QUERY("post", "", "ZYHotDocument{id img   name  }");
      this.hotlistlist = res.data.ZYHotDocument;
    },
    handleSelect() {
      let key = this.states;
      const dest = [];

      for (var i = 0; i < this.initiallist.length; i++) {
        var ai = this.initiallist[i].Price;

        switch (key) {
          case "1":
            // eslint-disable-next-line no-const-assign
            this.Collectionlist = this.initiallist;
            this.Collectionlist.sort(function(a, b) {
              return b.read - a.read;
            });
            break;
          case "2":
            if (ai == "0") {
              dest.push(this.initiallist[i]);
            }
            this.Collectionlist = dest;
            this.Collectionlist.sort(function(a, b) {
              return b.read - a.read;
            });
            break;
          case "3":
            if (ai !== "0") {
              dest.push(this.initiallist[i]);
            }
            this.Collectionlist = dest;
            this.Collectionlist.sort(function(a, b) {
              return b.read - a.read;
            });
            break;
        }
      }
    },
    change(item) {
      let user = this.$store.state.userinfo.id;
      updateread(item.id, item.read);
      if(user){
      integralAddOne(user); ///积分加1
      }
      this.$router.push({
        path: "/Resource_details",
        query: {
          id: item.id
        }
      });
    },
    handle(row, event, column) {
      // console.log(row);
      let user = this.$store.state.userinfo.id;
      updateread(row.id, row.read);
      integralAddOne(user); ///积分加1
      this.$router.push({
        path: "/Resource_details",
        query: {
          id: row.id
        }
      });
    },
    // 查询当前登录用户是否订阅该用户
    async initIsSubs() {
      let resp = await QUERYED(
        "post",
        "",
        "SubscribeUserInfo(where:{subscribers_user_id:{_eq:" +
          this.$store.state.userinfo.id +
          "},subscribed_user_id:{_eq:" +
          this.$route.query.id +
          "}}) {id subscribe_state}"
      );

      // 存在订阅信息
      if (resp.data.SubscribeUserInfo.length != 0) {
        // 订阅信息不止一条
        for (let i = 0; i < resp.data.SubscribeUserInfo.length; i++) {
          if (resp.data.SubscribeUserInfo[i].subscribe_state == 0) {
            // 找到有效的订阅信息
            this.modify_id = resp.data.SubscribeUserInfo[i].id;
            this.show3 = true;
          }
        }
      } else {
        this.show3 = false;
      }
      this.isSelf = true; // 避免闪烁
    },
    // 点击订阅
    async subsChoose() {
      // console.log(this.$store.state.userinfo);
      this.dialogVisible = false;
      if (
        this.$store.state.userinfo.id === undefined ||
        this.$store.state.userinfo.id === null ||
        this.$store.state.userinfo.id == ""
      ) {
        this.$message.warning({
          message: "请先登录!",
          duration: 1200,
          center: true
        });
        return;
      } else {
        // 查询当前用户知识币
        let resp = await QUERYED(
          "post",
          "",
          "User(where:{id:{_eq:" + this.$store.state.userinfo.id + "}}) {Money}"
        );
        // console.log("已登录用户money: ", resp);
        this.newestMoney = Number(resp.data.User[0].Money).toFixed(2);
      }
      // 1.判断该用户是否已设置订阅
      // let res = QUERY('post',"",'SubscribeSet_aggregate(where:{user_id:{_eq:'+this.$route.query.id+'}}) { aggregate {count}}')
      let response = QUERYED(
        "post",
        "",
        "SubscribeSet(where:{user_id:{_eq:" +
          this.$route.query.id +
          "}}) {id setType typeOfDate money discount} SubscribeSet_aggregate{ aggregate {count}}"
      );

      Promise.resolve(response)
        .then(result => {
          // console.log(result);
          if (result.data.SubscribeSet.length != 0) {
            // 该用户已设置订阅信息
            let info = result.data.SubscribeSet;
            for (let i = 0; i < info.length; i++) {
              if (info[i].setType == 6) {
                // 基本费用+折扣
                for (let j = 0; j < 3; j++) {
                  if (info[i].typeOfDate == 0) {
                    this.subsInfo.t_60 = info[i].money;
                    this.subsInfo.d_60 = info[i].discount;
                  } else if (info[i].typeOfDate == 1) {
                    this.subsInfo.t_61 = info[i].money;
                    this.subsInfo.d_61 = info[i].discount;
                  } else {
                    this.subsInfo.t_62 = info[i].money;
                    this.subsInfo.d_62 = info[i].discount;
                  }
                }
              } else {
                // 一次性付费
                if (info[i].typeOfDate == 3) {
                  this.subsInfo.t_73 = info[i].money;
                  // this.subsInfo.d_60 = info[i].discount;
                } else if (info[i].typeOfDate == 4) {
                  this.subsInfo.t_74 = info[i].money;
                  // this.subsInfo.d_61 = info[i].discount;
                } else {
                  this.subsInfo.t_75 = info[i].money;
                  // this.subsInfo.d_62 = info[i].discount;
                }
              }
            }
            // console.log("subsInfo,", this.subsInfo);
            this.show1 = true; // 显示 基本或一次性导航栏
            // 初始 基本+折扣 值
            this.baseMoney = this.subsInfo.t_60;
            this.baseDiscount = this.subsInfo.d_60;
            // 初始化 订阅核对信息
            this.subsInfoShow.type =
              "基本费用 (" +
              this.subsInfo.t_60 +
              ") " +
              "+折扣 (" +
              this.subsInfo.d_60 +
              ")";
            let now = new Date();
            this.subsInfoShow.expires = showTimeFormat(DateAdd("m ", 1, now));
            this.subsInfoShow.expires2 = showTimeFormat2(DateAdd("m ", 1, now));
            this.subsInfoShow.discount = this.subsInfo.d_60;
            this.subsInfoShow.coin = Number(
              subtr(this.newestMoney, this.subsInfo.t_60)
            ).toFixed(2);
            this.subsInfoShow.input = this.subsInfo.t_60;
          } else {
            // 该用户未设置订阅信息
            this.$message.info({
              message: "该用户暂未设置订阅信息,暂不提供订阅功能!",
              duration: 3000
            });
          }
        })
        .catch(error => {
          // console.log(error);
        });
    },
    // 取消订阅
    subsCancle() {
      this.centerDialogVisible26 = true;
    },
    // 基本 一次性
    isChoose(value) {
      // console.log(value);
      this.show_cost = true;
      if (value == 7) {
        this.baseRadio2 = 3;
        this.onceMoney = this.subsInfo.t_73;

        // 初始化 一次性 订阅核对
        this.changeShow(this.subsInfo.t_73, "无", "m ");
        this.payWay_type7 = true;
        this.payWay_type6 = false;
      } else {
        this.baseRadio = 0;
        this.baseMoney = this.subsInfo.t_60;
        this.baseDiscount = this.subsInfo.d_60;
        // 初始化 基本加折扣 订阅核对
        this.changeShow(this.baseMoney, this.baseDiscount, "m ");
        this.payWay_type7 = false;
        this.payWay_type6 = true;
      }
    },
    // 基本 一次性 月/季/年
    isChooseDataOfType(index) {
      // console.log(index);
      if (index < 3) {
        if (index == 1) {
          this.baseMoney = this.subsInfo.t_61;
          this.baseDiscount = this.subsInfo.d_61;
          this.changeShow(this.subsInfo.t_61, this.subsInfo.d_61, "q ");
        } else if (index == 2) {
          this.baseMoney = this.subsInfo.t_62;
          this.baseDiscount = this.subsInfo.d_62;
          this.changeShow(this.subsInfo.t_62, this.subsInfo.d_62, "y ");
        } else {
          this.baseMoney = this.subsInfo.t_60;
          this.baseDiscount = this.subsInfo.d_60;
          this.changeShow(this.subsInfo.t_60, this.subsInfo.d_60, "m ");
        }
      } else {
        if (index == 4) {
          this.onceMoney = this.subsInfo.t_74;
          this.changeShow(this.subsInfo.t_74, "无", "q ");
        } else if (index == 5) {
          this.onceMoney = this.subsInfo.t_75;
          this.changeShow(this.subsInfo.t_75, "无", "y ");
        } else {
          this.onceMoney = this.subsInfo.t_73;
          this.changeShow(this.subsInfo.t_73, "无", "m ");
        }
      }
    },
    // 基本费用加折扣
    /**
     * dateType string加空格
     */
    changeShow(cost, discount, dateType) {
      let now = new Date(); // 局部变量，私有化
      this.subsInfoShow.type =
        this.radio == 6
          ? "基本费用 (" + cost + ") +折扣 (" + discount + ")"
          : "一次性付费 (" + cost + ") +折扣 (" + discount + ")";
      this.subsInfoShow.expires = showTimeFormat(DateAdd(dateType, 1, now));
      this.subsInfoShow.expires2 = showTimeFormat2(DateAdd(dateType, 1, now));
      this.subsInfoShow.discount = discount;
      this.subsInfoShow.coin = Number(subtr(this.newestMoney, cost)).toFixed(2);
      this.subsInfoShow.input = cost;
    },
    // 取消支付
    cancel() {
      this.show1 = false;
      this.show_cost = false;
      this.payWay_type7 = false;
      this.payWay_type6 = false;
      this.radio = 0;
    },
    // 支付信息确认
    async confirmPays() {
      // console.log("1111");
      if (
        this.newestMoney < this.baseMoney ||
        this.newestMoney < this.onceMoney
      ) {
        this.$message.warning({
          message: "您的知识币不足,请前往个人中心充值",
          duration: 2000
        });
        return;
      }
      // this.payWay_type7 = false;
      // this.payWay_type6 = false;
      this.subsInfoShow.user = this.user;
      this.centerDialogVisible25 = true;
    },
    // 支付
    async finallyPay() {
      // console.log("this.subsInfoShow.expires",new Date(this.subsInfoShow.expires));
      // return
      this.payloading = true;
      // 插入数据
      let resp = await INSERT(
        "post",
        "",
        "insert_SubscribeUserInfo(objects:{subscribers_user_id:" +
          this.$store.state.userinfo.id +
          ',subscribers_user_name:"' +
          this.$store.state.userinfo.name +
          '",subscribed_user_id:' +
          this.$route.query.id +
          ',subscribed_user_name:"' +
          this.subsInfoShow.user +
          '",createTime:"' +
          new Date() +
          '",expires:"' +
          new Date(this.subsInfoShow.expires) +
          '",subscribe_type:"' +
          this.subsInfoShow.type +
          '"}) {affected_rows}'
      );
      if (resp.data.insert_SubscribeUserInfo.affected_rows == 1) {
        // 插入数据成功，更新user表 用户知识币数据
        let resp = await UPDATE(
          "post",
          "",
          "update_User(where:{id:{_eq:" +
            this.$store.state.userinfo.id +
            "}},_set:{Money:" +
            this.subsInfoShow.coin +
            "}) {affected_rows}"
        );

        /**
         * 修改store 中 state下 userinfo下 money
         */
        this.$store.commit("changeLoginUserMoney", this.subsInfoShow.coin);

        // 更新被订阅者的知识币
        let resp2 = await QUERYED(
          "post",
          "",
          "User(where:{id:{_eq:" + this.$route.query.id + "}}) { Money}"
        );
        let newstSpecie = accAdd(
          resp2.data.User[0].Money,
          this.subsInfoShow.input
        );
        // console.log("newstSpecie: ", newstSpecie);
        let resp3 = await UPDATE(
          "post",
          "",
          "update_User(where:{id:{_eq:" +
            this.$route.query.id +
            "}},_set:{Money:" +
            newstSpecie +
            "}) {affected_rows}"
        );

        if (
          resp.data.update_User.affected_rows != 1 &&
          resp2.data.User[0].length != 1 &&
          resp3.data.update_User.affected_rows != 1
        ) {
          this.$message.error({
            message: "服务器繁忙 稍后再试~",
            duration: 1600,
            center: true
          });
          this.centerDialogVisible25 = false;
          this.payloading = false;
          this.show1 = false;
          this.show_cost = false;
          return;
        } else {
          // 步骤完毕
          // this.subsInfoShow.user = this.user;
          this.centerDialogVisible25 = false;
          this.payloading = false;
          this.show1 = false;
          this.show3 = true;
          this.show_cost = false;
          this.$message.success({
            message: "订阅成功!",
            duration: 1500,
            center: true
          });
          // 订阅成功插入bill表 知识币消费记录
          let resp = await INSERTS(
            "post",
            "",
            "insert_bill(objects:{orderid:" +
              this.$route.query.id +
              ',time:"' +
              new Date() +
              '",type:' +
              3 +
              ",state:" +
              0 +
              ",userid:" +
              this.$store.state.userinfo.id +
              ',orderNum:"' +
              Bringbill() +
              '",Price:"' +
              this.subsInfoShow.input +
              '",classification:' +
              1 +
              ',subsed_user_name:"' +
              this.subsInfoShow.user +
              '"}) {affected_rows}'
          );
          // console.log("676: ", resp);
        }
      } else {
        this.$message.error({
          message: "服务器繁忙 稍后再试~",
          duration: 1600,
          center: true
        });
        this.centerDialogVisible25 = false;
        this.payloading = false;
        this.show1 = false;
        this.show_cost = false;
        return;
      }
    },
    // 确定取消已订阅用户
    async cancelConfirm() {
      // 根据SubscribeUserInfo 返回的 id 修改该id 行 subscribe_state 为 1
      // console.log("modify_id: ", this.modify_id);
      this.centerDialogVisible26 = false;
      // let resp = await DELETE('post',"",'delete_SubscribeUserInfo(where:{subscribers_user_id:{_eq:'+
      //   this.$store.state.userinfo.id+'},subscribed_user_id:{_eq:'+this.$route.query.id+'}}) {affected_rows}');
      let resp = await UPDATES(
        "post",
        "",
        "update_SubscribeUserInfo(where:{id:{_eq:" +
          this.modify_id +
          "}},_set:{subscribe_state:" +
          1 +
          "}) {affected_rows}"
      );
      // console.log("quxiao: ", resp);
      if (resp.data.update_SubscribeUserInfo.affected_rows == 1) {
        this.show3 = false;
        this.$message.success({
          message: "取消订阅成功!",
          duration: 1500,
          center: true
        });
      } else {
        this.$message.error({
          message: "服务器繁忙,请稍后再试~",
          duration: 1500,
          center: true
        });
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    YearMMdd(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    }
  }
};
</script>
<style scoped>
#header{
  position: relative;
}
/* public css */
#app {
  font-size: 12px;
}
html {
  font-size: 12px;
}
.center {
  text-align: center;
}
.font16 {
  font-size: 16px;
}
.font14 {
  font-size: 14px;
}
.padd15 {
  padding: 15px;
}
.tip2 {
  color: #ff7171 !important;
  font-size: 14px !important;
}
.pad15-t {
  padding-top: 15px;
}
.pad15-t-b {
  padding-bottom: 15px;
  padding-top: 15px;
}
.color-000 {
  color: #000000;
}
#subsWay #border-b {
  border-bottom: 1px solid #e2e2e2;
  text-align: center;
  color: #000000;
  letter-spacing: 1px;
  padding-bottom: 5px;
}
.border-b {
  border-bottom: 1px solid #e2e2e2;
  /* text-align: center; */
  color: #000000;
  letter-spacing: 1px;
  padding-bottom: 5px;
}
.public-div {
  padding: 10px 40px 20px;
}
/* -------------- */
.is-vertical {
  /* margin: 75px 141px 79px 223px; */
}
.el-header,
.el-footer {
  background-color: #1a417c;
  color: #ffffff;

  height: 20px;
}
.el-menu {
  background-color: #eeeeee;
}
.el-aside {
  color: #333;
  text-align: center;
  background-color: #eeeeee;
  margin-left: 0;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
}
.el-table {
  font-size: 12px;
}
.el-header .el-image {
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  float: left;
  margin-top: 20px;
  margin-right: 30px;
}
.xq {
  overflow: hidden;
  width: 60%;
}
.zc {
  line-height: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.zc .ts_1 {
  margin-right: 50px;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 203, 250, 1);
}
.ts_1 img {
  width: 15px;
}
.zc_title {
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.ts_2 {
  color: #f26f05;
  font-size: 16px;
}
.zc_introduce {
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(254, 254, 254, 1);
  margin-top: 10px;
}
.el-header .right {
  float: right;
  margin-top: -90px;
  margin-right: 60px;
}
.el-header .right .ts_3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.el-header .right .ts_3 img {
  width: 17px;
  float: left;
}
.cell img {
  width: 26px;
  height: 30px;
}
.pf {
  padding: 40px 0px 30px 0px;
}
.Hotfile {
  margin-top: 2px;
  background-color: #eeeeee;
  padding-top: 10px;
  border-top: 1px solid #b5b5b5;
}
.Hotfile_center {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.Hotfile .plcont {
  cursor: pointer;
  text-align: left;
  width: 130px;
  /* float: left; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 40px;
}

.Hotfile .gg {
  font-size: 14px;
}
.Hotfile ul li {
  line-height: 30px;
}
.Hotfile ul li .inx {
  margin-right: 10px;
}
#header {
  position: relative;
}
.subs {
  position: absolute;
  top: 50%;
  right: 29px;
  transition: translateY();
  transform: translateY(-50%);
}
#subsWay {
  position: absolute;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  right: 88px;
  top: 61%;
  border: 1px solid #cccccc;
  z-index: 1;
}
#subsCost {
  display: inline-block;
  position: absolute;
  background: #ffffff;
  top: 236%;
  right: 11%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 0.036765rem;
  border: 0.007353rem solid #cccccc;
  padding: 0.036765rem;
  font-size: 0.088235rem;
  color: #606266;
  width: 41%;
}
#subsCost .tip span {
  display: inline-block;
  width: 33.3%;
}
.detailCost {
  padding-top: 20px;
  padding-bottom: 20px;
}
.detailCost > font {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

/* reset css */
#subsWay >>> .el-radio-group .el-radio {
  display: block;
  margin-bottom: 10px;
  margin-right: 0.220588rem;
}
#subsCost >>> .el-radio-group {
  display: block;
  text-align: center;
}
#subsCost >>> .el-radio-group .el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 0px;
  width: 33.3%;
  /* margin-left: 1px; */
}
.submitBtn >>> .el-button:last-child {
  background: #eb5b3b;
  color: #ffffff;
}
#app >>> .el-dialog--center .el-dialog__body {
  /* text-align: initial; */
  padding: 25px 20px 30px 50px;
}
/* 添加 */
.aoo_content {
  display: flex;
  /* background-color: cyan; */
  width: 1130px;
  margin: auto;
}
.app_left,
.app_right {
}
.app_left {
  width: 800px;
  margin-right: 30px;
}
.app_right {
  width: 300px;
  /* background: rgb(255, 255, 255); */
}
.right_top {
  width: 100%;
  height: 1.102941rem;
  line-height: 1.102941rem;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 20px;
  /* font-weight: bold; */
  display: inline-block;
  background-size: 100% 100%;
  background-image: url("~@/assets/9393d09d9e0812e1cdb0a95f2c316b0.jpg");
}
.mar_top {
  margin-top: 30px;
}
.VVVV {
  color: #fda444;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 100px;
  left: 90px;
  display: inline-block;
  width: 20px;
  height: 20px;
  /* opacity: 0.85; */
  background-size: 100% 100%;
  background-image: url("~@/assets/icon/v.png");
}
</style>
