<template>
  <div class="back">
    <div class="top">
      <i class="el-icon-reading"></i>
      {{personality}}
    </div>
    <div class="border" v-for="(item,index) in  polymerization" :key="index">
      <div class="name">{{item.name}}</div>
      <div class="title">{{item.title}}</div>
    </div>
    <div class="polymerization" v-if="polymerization.length==0">暂无推荐</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    personality: { type: String, required: true },
    polymerization: { type: Array, required: true }
  }
};
</script>
<style  scoped>
.el-icon-reading {
  font-size: 27px;
  font-weight: bold;
  color: rgb(35, 157, 238);
  position: relative;
  top: 3px;
}
.top {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: 550;
  color: rgb(35, 157, 238);
  padding-left: 10px;
}
.name {
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  height: 30px;
  line-height: 30px;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  width: 100%;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  color: rgb(167, 167, 167);
}
.border {
  padding: 10px;
  border-top: 1px dashed rgb(199, 199, 199);
}
.back {
  background: rgb(255, 255, 255);
  width: 100%;
}
.polymerization{
    color: rgb(175, 172, 172);
    font-size: 15px;
    padding: 10px;
}
</style>